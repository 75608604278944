import React, { useState } from 'react';
import styled from 'styled-components';
import { Block } from './Block';
import { Heading } from './Heading';
import { Row, Box } from './BasicComponents';
import { Paragraph } from './Paragraph';
import { Table } from './Table';
import kanalT from '../assets/images/kanal-tabulka.png';
import arrow from '../assets/images/sipka.svg';
import utierky from '../assets/images/utierky.png';
import plienka from '../assets/images/plienka.png';
import olej from '../assets/images/olej.png';
import jedlo from '../assets/images/jedlo.png';
import chemikalie from '../assets/images/chemikalie.png';
import lieky from '../assets/images/lieky.png';

const data = [
  {
    img: utierky,
    heading: 'Vlhčené utierky',
    text: 'Najväčším nepriateľom kanalizácie sú vlhčené utierky. Upchávajú potrubia a sťažujú prácu čerpacím zariadeniam. Vyhoďte ich preto do koša. Vďaka tomuto jednoduchému návyku predídete mnohým problémom.',
  },
  {
    img: plienka,
    heading: 'Hygienické produkty',
    text: 'Plienky, tampóny, vložky a ďalšie hygienické produkty vyhoďte do koša.',
  },
  {
    img: olej,
    heading: 'Tuky',
    text: 'Olej či masť nechajte vychladnúť, dajte do fľaše, zatvorte a odneste na zberné miesto. Prípadne ich zmiešajte so semienkami a orieškami a použite ako potravu pre vtáčiky.',
  },
  {
    img: jedlo,
    heading: 'Zvyšky jedla a kosti',
    text: 'Zvyšky jedla vyhoďte do koša, prípadne ich môžete kompostovať.',
  },
  {
    img: chemikalie,
    heading: 'Chemikálie',
    text: 'Rozpúšťadlá, motorový olej či farby sú vo vode nerozpustné a usadzujú sa na potrubiach kanalizácie, čím ju upchávajú. Zároveň ohrozujú proces čistenia odpadových vôd. Správny postup likvidácie je odniesť ich do zbernej stanice.',
  },
  {
    img: lieky,
    heading: 'Lieky, striekačky a tabletky',
    text: 'Všetky takéto zdravotnícke pomôcky a nepoužité liečivá odneste do lekárne.',
  },
];

export const BlockC = () => {
  const [focused, setFocused] = useState(0);
  const left = focused === 0 ? data.length - 1 : focused - 1;
  const leftOutOfView = left === 0 ? data.length - 1 : left - 1;
  const right = focused === data.length - 1 ? 0 : focused + 1;
  const rightOutOfView = right === data.length - 1 ? 0 : right + 1;

  const moveHandler = plus => {
    let newFocused;
    if (plus) {
      newFocused = focused === data.length - 1 ? 0 : focused + 1;
    } else {
      newFocused = focused === 0 ? data.length - 1 : focused - 1;
    }
    setFocused(newFocused);
  };

  const tableList = data.map((table, i) => {
    const isFocused = i === focused;
    const isLeft = i === left;
    const isLeftOutOfView = i === leftOutOfView;
    const isRight = i === right;
    const isRightOutOfView = i === rightOutOfView;
    const isNotVisible =
      !isFocused &&
      !isLeft &&
      !isLeftOutOfView &&
      !isRight &&
      !isRightOutOfView;
    return (
      <Table
        img={table.img}
        heading={table.heading}
        isFocused={isFocused}
        isLeft={isLeft}
        isLeftOutOfView={isLeftOutOfView}
        isRight={isRight}
        isRightOutOfView={isRightOutOfView}
        isNotVisible={isNotVisible}
      >
        {table.text}
      </Table>
    );
  });
  return (
    <BlockStyled id='C'>
      <HeadingStyled>Čo ešte pán Kanál nemá rád?</HeadingStyled>
      <KanalStyled />
      <ParagraphStyled>
        Nasledovné veci, prosím, nevhadzujte <NoWrap>do kanalizácie</NoWrap>.
        Predídete tak nielen upchávaniu potrubí, ale aj znečisťovaniu životného
        prostredia.
      </ParagraphStyled>
      <TableWrapper>
        {tableList}
        <ArrowStyled onClick={() => moveHandler(false)} />
        <ArrowStyled isRight onClick={() => moveHandler(true)} />
      </TableWrapper>
      <ParagraphBStyled>
        Pre kanalizáciu je najhoršie, ak sa viac takýchto druhov odpadu spojí
        a vytvorí zhluky, ktoré upchávajú kanalizačné potrubia.
      </ParagraphBStyled>
    </BlockStyled>
  );
};

const NoWrap = styled.span`
  display: inline-flex;
`;
const ParagraphStyled = styled(Paragraph)`
  margin: 1rem 0;
  padding: 0 1rem;
  width: 100%;
  max-width: 44rem;
  position: relative;
  top: -3.5rem;
`;
const ParagraphBStyled = styled(Paragraph)`
  margin: 1rem 0;
  padding: 0 1rem;
  width: 100%;
  max-width: 44rem;
`;

const KanalStyled = styled(Box)`
  background: url(${kanalT}) center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 20rem;
`;
const ArrowStyled = styled.button`
  background: url(${arrow}) center center no-repeat;
  width: 3rem;
  height: 3rem;
  transform: rotate(${props => (props.isRight ? '90deg' : '-90deg')});
  position: absolute;
  border: 0;
  outline: none;
  cursor: pointer;
  left: ${props => (props.isRight ? '' : '14%')};
  right: ${props => (props.isRight ? '14%' : '')};
  @media (max-width: 560px) {
    left: ${props => (props.isRight ? '' : '10%')};
    right: ${props => (props.isRight ? '10%' : '')};
  }
  @media (max-width: 400px) {
    left: ${props => (props.isRight ? '' : '7%')};
    right: ${props => (props.isRight ? '7%' : '')};
  }
  @media (max-width: 350px) {
    left: ${props => (props.isRight ? '' : '5px')};
    right: ${props => (props.isRight ? '5px' : '')};
  }
`;
const TableWrapper = styled(Row)`
  width: 670px;
  height: 21rem;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 100%;
    max-width: 560px;
  }
`;
const BlockStyled = styled(Block)`
  position: relative;
  padding: 3rem 0;
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: 4rem;
`;
