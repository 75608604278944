import React from 'react';
import styled from 'styled-components';
import { Block } from './Block';
import { Heading } from './Heading';
import download from '../assets/images/download.png';
import video1 from '../assets/videos/BVS_video_uhlavny_nepriatel.mp4';
import video1Thumbnail from '../assets/images/video1Thumbnail.png';
import video2 from '../assets/videos/BVS_video_nelej_ohen.mp4';
import video2Thumbnail from '../assets/images/video2Thumbnail.png';
import video3 from '../assets/videos/BVS_video_patalie_s_chemikaliami.mp4';
import video3Thumbnail from '../assets/images/video3Thumbnail.png';

export const BlockB = () => (
  <BlockStyled id='B'>
    <HeadingStyled>Toto sú úhlavní nepriatelia pána Kanála</HeadingStyled>
    <VideoStyled
      width='100%'
      height='100%'
      controls
      src={video1}
      type='video/mp4'
      poster={video1Thumbnail}
      preload='auto'
    ></VideoStyled>
    <LinkStyled
      href='https://kanalniejesmetisko.sk/BVS_video_uhlavny_nepriatel.mp4'
      download
    >
      stiahnuť video
    </LinkStyled>

    <VideoStyled
      width='100%'
      height='100%'
      controls
      src={video2}
      type='video/mp4'
      poster={video2Thumbnail}
      preload='auto'
    ></VideoStyled>
    <LinkStyled
      href='https://kanalniejesmetisko.sk/BVS_video_nelej_ohen.mp4'
      download
    >
      stiahnuť video
    </LinkStyled>

    <VideoStyled
      width='100%'
      height='100%'
      controls
      src={video3}
      type='video/mp4'
      poster={video3Thumbnail}
      preload='auto'
    ></VideoStyled>
    <LinkStyled
      href='https://kanalniejesmetisko.sk/BVS_video_patalie_s_chemikaliami.mp4'
      download
    >
      stiahnuť video
    </LinkStyled>
  </BlockStyled>
);

const BlockStyled = styled(Block)`
  padding: 5rem 3rem 0;
  @media (max-width: 700px) {
    padding: 2rem 1rem 0;
  }
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: 4rem;
`;
const VideoStyled = styled.video`
  margin: 2rem 0;
  width: 100%;
  max-width: 820px;
  max-height: 461px;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: 0.8rem 0.8rem 0rem 0rem rgba(0, 0, 0, 0.15);
  @media (max-width: 500px) {
    border-radius: 0.7rem;
  }
`;

const LinkStyled = styled.a`
  background: url(${download}) no-repeat;
  background-size: contain;
  color: #fff;
  font-weight: 800;
  font-size: 0.8rem;
  padding: 1.2rem;
  padding-top: 0.65rem;
  text-decoration: none;
  margin: 1rem;
`;
