import React from 'react';
import styled from 'styled-components';
import { Row } from './BasicComponents';
import { Logo } from './Logo';

export const Header = () => (
  <HeaderStyled>
    <HeaderInnerStyled>
      <Logo />
    </HeaderInnerStyled>
  </HeaderStyled>
);

const HeaderStyled = styled(Row)`
  background: rgb(4, 171, 255);
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
`;
const HeaderInnerStyled = styled(Row)`
  width: 90%;
  max-width: 1250px;
  height: 100%;
`;
