import React from 'react';
import styled from 'styled-components';
import { Row, Box } from './BasicComponents';
import { Block } from './Block';

export const Footer = () => (
  <Block bg='rgb(4, 171, 255)' padding='1rem'>
    <RowStyled>
      <LeftBoxStyled>
        <a href='https://www.bvsas.sk/' target='_blank' rel='noreferrer'>
          © Bratislavská vodárenská spoločnosť, a.s.
        </a>
      </LeftBoxStyled>
      <RightBoxStyled>
        <a href='https://www.bvsas.sk/' target='_blank' rel='noreferrer'>
          www.bvsas.sk
        </a>
      </RightBoxStyled>
    </RowStyled>
  </Block>
);

const RowStyled = styled(Row)`
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1250px;
  align-items: center;
  padding: 0.5rem 1rem;
  font-family: DINProBVS;
  & a {
    color: #fff;
  }
  & a:hover {
    text-decoration: none;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftBoxStyled = styled(Box)`
  align-items: flex-start;
  @media (max-width: 800px) {
    text-align: center;
    align-items: center;
  }
`;
const RightBoxStyled = styled(Row)`
  align-items: flex-start;
  padding: 1.5rem 0;
  @media (max-width: 800px) {
    text-align: center;
    align-items: center;
  }
`;
