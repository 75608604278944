import React from 'react';
import styled from 'styled-components';
import { Box } from './BasicComponents';

export const Heading = ({ className, children, main }) => (
  <HeadingStyled className={className} as={main ? 'h1' : 'h2'}>
    {children}
  </HeadingStyled>
);

const HeadingStyled = styled(Box)`
  margin: 0;
  padding: 0;
  color: #00acff;
  font-weight: 800;
  font-size: 2.4rem;
  text-align: center;
  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
`;
