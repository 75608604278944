import React from 'react';
import styled from 'styled-components';

import { Box } from './BasicComponents';
import tabulka from '../assets/images/tabulka.png';

export const Table = ({
  img,
  heading,
  children,
  isFocused,
  isLeft,
  isLeftOutOfView,
  isRight,
  isRightOutOfView,
  isNotVisible,
}) => {
  let translateValue = '0%';
  if (isLeft) {
    translateValue = '-106%';
  }
  if (isLeftOutOfView) {
    translateValue = '-212%';
  }
  if (isRight) {
    translateValue = '+106%';
  }
  if (isRightOutOfView) {
    translateValue = '+212%';
  }
  return (
    <TableStyled
      isFocused={isFocused}
      isNotVisible={isNotVisible}
      translateValue={translateValue}
    >
      <ImgStyled img={img} />
      <HeadingStyled>{heading}</HeadingStyled>
      <TextStyled>{children}</TextStyled>
    </TableStyled>
  );
};

const TableStyled = styled(Box)`
  background: url(${tabulka}) no-repeat;
  background-size: contain;
  width: 13rem;
  height: 21rem;
  margin: 0.5rem;
  padding: 1rem;
  align-items: center;
  text-align: center;
  color: #5b5b5b;
  opacity: ${props => (!props.isFocused ? '0.25' : '1')};
  display: ${props => (props.isNotVisible ? 'none' : 'flex')};
  position: absolute;
  transition: all 0.4s;
  transform: translateX(${props => props.translateValue});
  @media (max-width: 800px) {
    width: 11rem;
    min-width: 11rem;
    height: 18rem;
    min-height: 18rem;
  }
`;

const ImgStyled = styled(Box)`
  background: url(${props => props.img}) center center no-repeat;
  background-size: contain;
  width: 5rem;
  height: 5rem;
  max-height: 6rem;
  justify-content: center;
  align-items: center;
`;
const HeadingStyled = styled(Box)`
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 800;
  @media (max-width: 800px) {
    font-size: 1rem;
  }
`;
const TextStyled = styled(Box)`
  padding: 0rem;
  font-size: 0.7rem;
  font-weight: 400;
  @media (max-width: 800px) {
    font-size: 0.6rem;
  }
`;
