import React from 'react';
import styled from 'styled-components';

export const Paragraph = ({ className, children }) => (
  <ParagraphStyled className={className}>{children}</ParagraphStyled>
);

const ParagraphStyled = styled.p`
  margin: 1rem 0;
  padding: 0;
  font-size: 2rem;
  color: #fff;
  text-align: center;
  font-weight: 800;
  & a {
    color: #fff;
  }
  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
`;
