import React from 'react';
import {hydrate, render} from 'react-dom';
import TagManager from 'react-gtm-module'
import App from './App';
import './index.css';


window.isRealBrowser = false;

const tagManagerArgs = {
    gtmId: 'GTM-M6VKZ5K'
}

const snap = navigator.userAgent === 'ReactSnap';
// const production = process.env.NODE_ENV === 'production';
if (!snap) {
    TagManager.initialize(tagManagerArgs)
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    window.isRealBrowser = true;
    hydrate(<App/>, rootElement);
} else {
    render(<App/>, rootElement);
}
