import React from 'react';
import { Header } from './components/Header';
import { BlockA } from './components/BlockA';
import { BlockB } from './components/BlockB';
import { BlockC } from './components/BlockC';
import { BlockD } from './components/BlockD';
import { Footer } from './components/Footer';

const App = () => (
  <>
    <Header />
    <BlockA />
    <BlockB />
    <BlockC />
    <BlockD />
    <Footer />
  </>
);

export default App;
