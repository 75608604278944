import React from 'react';
import styled from 'styled-components';

import { Block } from './Block';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';
import back from '../assets/images/back.jpg';

export const BlockA = () => {
  return (
    <BlockStyled id='A' bgImg={back} height='100vh'>
      <HeadingStyled main>Kanál nie je smetisko</HeadingStyled>
      <ParagraphStyled>
        Veľa ľudí si kanalizáciu mýli <NoWrap>s odpadkovým</NoWrap> košom.
        <br />
        „Veď je to také ľahké – stačí hodiť <NoWrap>do záchoda</NoWrap>,
        spláchnuť a hotovo!“
        <br />
        Bohužiaľ, takto to nefunguje.
        <br />
        Aj keď niečo ide spláchnuť, ešte to neznamená, že to patrí{' '}
        <NoWrap>do kanalizácie</NoWrap>.<br />
        Mnoho vecí, ktoré ľudia bežne zvyknú vyhadzovať{' '}
        <NoWrap>do záchoda</NoWrap> či vypúšťať <NoWrap>do výlevky</NoWrap>,
        <br />
        upcháva kanalizačné potrubia, spôsobuje škody a sťažuje proces
        <br />
        čistenia odpadových vôd.
      </ParagraphStyled>
    </BlockStyled>
  );
};
const NoWrap = styled.span`
  display: inline-flex;
`;
const BlockStyled = styled(Block)`
  padding-top: 16.5rem;
  @media (max-width: 600px) {
    background-position: -32rem top;
  }
`;
const HeadingStyled = styled(Heading)`
  @media (max-width: 500px) {
    font-size: 2.4rem;
  }
`;
const ParagraphStyled = styled(Paragraph)`
  padding-top: 40rem;
  width: 100%;
  max-width: 50rem;
`;
