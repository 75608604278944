import React from 'react';
import styled from 'styled-components';

export const Box = ({ className, children, id }) => (
  <BoxStyled id={id} className={className}>
    {children}
  </BoxStyled>
);

export const Row = ({ className, children, onClick }) => (
  <RowStyled onClick={onClick} className={className}>
    {children}
  </RowStyled>
);

const BoxStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowStyled = styled.div`
  display: flex;
`;
