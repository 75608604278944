import React from 'react';
import styled from 'styled-components';
import { Block } from './Block';
import { Box } from './BasicComponents';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';
import bottom from '../assets/images/bottom.jpg';
import kanal from '../assets/images/kanal.png';

export const BlockD = () => {
  return (
    <Block id='D' bgImg={bottom}>
      <Heading>Do kanalizácie patrí</Heading>
      <ParagraphStyled>
        výlučne odpadová voda. To znamená použitá voda z kuchyne, kúpeľne, WC a
        toaletný papier.
      </ParagraphStyled>
      <KanalStyled />
    </Block>
  );
};

const ParagraphStyled = styled(Paragraph)`
  margin: 0rem;
  font-weight: 800;
  text-align: center;
  width: 100%;
  max-width: 38rem;
`;

const KanalStyled = styled(Box)`
  background: ${`url(${kanal}) center center no-repeat`};
  background-size: contain;
  width: 100%;
  height: 25rem;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  @media (max-width: 450px) {
    height: 20rem;
  }
`;
