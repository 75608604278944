import React from 'react';
import styled from 'styled-components';
import { Box } from './BasicComponents';

export const Block = ({
  className,
  children,
  bg,
  bgImg,
  padding = '3rem 1rem',
  height = 'auto',
  minHeight,
  id,
}) => (
  <BlockStyled
    id={id}
    className={className}
    bg={bg}
    bgImg={bgImg}
    padding={padding}
    height={height}
    minHeight={minHeight}
  >
    {children}
  </BlockStyled>
);

const BlockStyled = styled(Box)`
  background: ${props =>
    props.bgImg ? `url(${props.bgImg}) top center no-repeat` : props.bg};
  width: 100%;
  color: #fff;
  align-items: center;
  text-align: center;
  padding: ${props => props.padding};
  min-height: ${props => props.height};
  min-height: ${props => props.minHeight};
`;
