import React from 'react';
import styled from 'styled-components';
import bvsLogo from '../assets/images/BVS_logo.svg';
import { Link } from 'react-scroll';

export const Logo = () => {
  return <LogoStyled to='A' smooth={true} duration={400} logo={bvsLogo} />;
};

const LogoStyled = styled(Link)`
  background: ${props => `url(${props.logo}) center center no-repeat`};
  background-size: contain;
  width: 200px;
  height: 100%;
  cursor: pointer;
`;
